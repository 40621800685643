const ObservationFormActionTypes = {
    SET_OBSERVATION_FORM: 'SET_OBSERVATION_FORM',
    SET_OBSERVATION_DETAILS: 'SET_OBSERVATION_DETAILS',
    SET_STANDARD_ONE: 'SET_STANDARD_ONE',
    SET_STANDARD_TWO: 'SET_STANDARD_TWO',
    SET_STANDARD_THREE: 'SET_STANDARD_THREE',
    SET_STANDARD_FOUR: 'SET_STANDARD_FOUR',
    SET_EVALUATION_SCORE: 'SET_EVALUATION_SCORE',
    SET_OBSERVATION_NOTES: 'SET_OBSERVATION_NOTES',
    SET_SUBMISSION_MESSAGE: 'SET_SUBMISSION_MESSAGE',
    RESET_SUBMISSION_MESSAGE: 'RESET_SUBMISSION_MESSAGE',
    SUBMIT_OBSERVATION_FORM_START: 'SUBMIT_OBSERVATION_FORM',
    SUBMIT_OBSERVATION_FORM_SUCCES: 'SUBMIT_OBSERVATION_FORM_SUCCES',
    SUBMIT_OBSERVATION_FORM_FAIL: 'SUBMIT_OBSERVATION_FORM_FAIL',
    RESET_OBSERVATION_FORM: 'RESET_OBSERVATION_FORM',
    DELETE_OBSERVATION_FORM_START: 'DELETE_OBSERVATION_FORM_START',
    DELETE_OBSERVATION_FORM_SUCCESS: 'DELETE_OBSERVATION_FORM_SUCCESS',
    DELETE_OBSERVATION_FORM_FAIL: 'DELETE_OBSERVATION_FORM_FAIL'
};

export default ObservationFormActionTypes;