const GradePolicyActionTypes = {
    SET_GRADE_POLICY_CHECKS_START: 'SET_GRADE_POLICY_CHECKS_START',
    SET_GRADE_POLICY_CHECKS_SUCCESS: 'SET_GRADE_POLICY_CHECKS_SUCCESS',
    SET_GRADE_POLICY_CHECKS_FAIL: 'SET_GRADE_POLICY_CHECKS_FAIL',
    SUBMIT_GRADE_POLICY_CHECK_START: 'SUBMIT_GRADE_POLICY_CHECK_START',
    SUBMIT_GRADE_POLICY_CHECK_SUCCESS: 'SUBMIT_GRADE_POLICY_CHECK_SUCCESS',
    SUBMIT_GRADE_POLICY_CHECK_FAIL: 'SUBMIT_GRADE_POLICY_CHECK_FAIL',
    RESET_GRADE_POLICY_CHECKS: 'RESET_GRADE_POLICY_CHECKS',
    UPDATE_GRADE_POLICY_CHECK: 'UPDATE_GRADE_POLICY_CHECK',
}

export default GradePolicyActionTypes;